.page-home {
  .products {
    padding: (155 * 100vw / 1903) (80 * 100vw / 1903) (100 * 100vw / 1903);
    display: flex;
    @include media($w1024) {
      padding: 30px 0 40px;
      flex-wrap: wrap;
    }
    &__copy {
      flex: 0 0 31%;
      position: relative;
      @include media($w1024) {
        flex: 0 0 100%;
      }
      .copy {
        &__main {
          width: 100%;
          margin: 0 0 (70 * 100vw / 1903);
          @include media($w1024) {
            margin: 0 0 40px;
            padding: 0 30px;
          }
          h2 {
            margin: 0 0 (55 * 100vw / 1903);
            @include media($w1024) {
              margin: 0 0 25px;
            }
          }
          p {
            max-width: (330 * 100vw / 1903);
            @include media($w1024) {
              max-width: none;
            }
          }
        }
        &__idea {
          border-radius: (30 * 100vw / 1903);
          background-color: $red;
          width: 100%;
          color: $white;
          padding: (80 * 100vw / 1903) (55 * 100vw / 1903) (60 * 100vw / 1903);
          box-shadow: 0 (20 * 100vw / 1903) (40 * 100vw / 1903) 0 rgba($black,0.1);
          @include media($w1024) {
            border-top: 1px solid rgba($black,0.1);
            border-radius: 0;
            flex: 0 0 100%;
            margin: 0;
            padding: 35px 30px 40px 105px;
            box-shadow: 0 0 0 0 rgba($black,0);
          }
          @include media($w560) {
            overflow: hidden;
            padding: 35px 30px 40px;
          }
          h4 {
            font-weight: 600;
            margin: 0 0 (70 * 100vw / 1903);
            padding: 0 0 0 (165 * 100vw / 1903);
            position: relative;
            @include media($w1024) {
              margin: 0 0 20px;
              padding: 0;
            }
            &:before {
              @include translateY;
              content: '';
              background-image: url(../images/icon--homeProducts.svg);
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
              width: (110 * 100vw / 1903);
              height: (150 * 100vw / 1903);
              position: absolute;
              top: calc(50% + (4 * 100vw / 1903));
              left: (10 * 100vw / 1920);
              @include media($w1024) {
                width: 60px;
                height: 70px;
                top: calc(50% + 2px);
                left: -75px;
              }
              @include media($w560) {
                width: 160px;
                height: 170px;
                z-index: -25;
                top: 55px;
                left: -110px;
              }
            }
          }
          p {
            max-width: (430 * 100vw / 1903);
            margin: 0 0 (48 * 100vw / 1903);
            @include media($w1024) {
              max-width: none;
              margin: 0 0 25px;
              padding: 0;
            }
          }
        }
      }
    }
    &__list {
      flex: 0 0 69%;
      overflow: hidden;
      padding: 0 0 0 (80 * 100vw / 1903);
      flex-wrap: wrap;
      justify-content: space-between;
      display: flex;
      @include media($w1024) {
        flex: 0 0 100%;
        overflow: hidden;
        margin: 0;
        padding: 20px 30px 0;
      }
      @include media($w768) {
        padding: 20px 0 0;
      }
      @include media($w560) {
        padding: 10px 0 0;
      }
      .list {
        &__item {
          flex: 0 0 50%;
          padding: 0 (50 * 100vw / 1903) (70 * 100vw / 1903) 0;
          position: relative;
          @include media($w1024) {
            padding: 0 15px 30px 0;
          }
          @include media($w768) {
            flex: 0 0 100%;
            padding: 0 30px 30px;
          }
          &:after {
            content: '';
            background-image: url(../images/home--products--border.jpg);
            background-size: contain;
            background-repeat: repeat-x;
            width: 100%;
            height: (11 * 100vw / 1903);
            position: absolute;
            bottom: (20 * 100vw / 1903);
            left: 0;
            @include media($w1024) {
              height: 8px;
              bottom: 0;
            }
          }
          &:nth-child(2n+2) {
            padding: 0 0 (70 * 100vw / 1903) (50 * 100vw / 1903);
            @include media($w1024) {
              padding: 0 0 30px 15px;
            }
            @include media($w768) {
              padding: 0 30px 30px;
            }
          }
          & > img {
            width: 100%;
            @include media($w768) {
              width: 500px;
              max-width: 100%;
              margin: 0 auto;
            }
          }
          .item {
            &__copy {
              position: relative;
              &:after {
                content: '';
                background-image: url(../images/home--products--arrow.svg);
                background-size: cover;
                width: (49 * 100vw / 1903);
                height: (16 * 100vw / 1903);
                position: absolute;
                bottom: (1 * 100vw / 1903);
                right: 0;
                @include media($w1024) {
                  width: 35px;
                  height: 11px;
                  bottom: 2px;
                }
              }
              h3 {
                @include transition;
                font-weight: 600;
                margin: 0 0 (17 * 100vw / 1903);
                @include media($w1024) {
                  margin: 0 0 8px;
                }
              }
              p {
                max-width: (300 * 100vw / 1903);
                @include media($w1024) {
                  max-width: calc(100% - 55px);
                }
              }
            }
            &__click {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
          &:hover {
            .item {
              &__copy {
                h3 {
                  color: $red;
                }
              }
            }
          }
        }
      }
    }
  }
}
