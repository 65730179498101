.page-contact {
  .map {
    border-radius: (30 * 100vw / 1903) (30 * 100vw / 1903) 0 0;
    background-color: $white;
    height: (600 * 100vw / 1903);
    overflow: hidden;
    position: relative;
    z-index: 50;
    @include media($w1024) {
      border-radius: 15px 15px 0 0;
      height: 400px;
      margin: -15px 0 0;
    }
    @include media($w560) {
      height: 350px;
    }
    &__map {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
