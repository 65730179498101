.page-products {
  .content {
    border-radius: (30 * 100vw / 1903) (30 * 100vw / 1903) 0 0;
    background-color: $white;
    padding: (70 * 100vw / 1903) 0;
    position: relative;
    z-index: 75;
    @include media($w1024) {
      border-radius: 15px 15px 0 0;
      margin: -15px 0 0;
      padding: 30px 0;
    }
    @include media($w560) {
      padding: 0 0 30px;
    }
    &__products {
      margin: 0 0 (1 * 100vw / 1903);
      flex-wrap: wrap;
      display: flex;
      @include media($w1024) {
        margin: 0 0 1px;
      }
      @include media($w560) {
        width: calc(100% + 60px);
        margin: 0 -30px 1px;
      }
      .products {
        &__item {
          border: (1 * 100vw / 1903) solid $gray;
          flex: 0 0 50%;
          margin: 0 0 (-1 * 100vw / 1903);
          position: relative;
          @include media($w1024) {
            border-width: 1px;
            margin: 0 0 -1px;
          }
          @include media($w560) {
            border: 0;
            border-bottom: 1px solid $gray;
            flex: 0 0 100%
          }
          &:nth-child(2n+1) {
            flex: 0 0 calc(50% + (1 * 100vw / 1903));
            margin: 0 (-1 * 100vw / 1903) (-1 * 100vw / 1903) 0;
            @include media($w1024) {
              flex: 0 0 calc(50% + 1px);
              margin: 0 0 -1px -1px;
            }
            @include media($w560) {
              flex: 0 0 100%;
              margin: 0 0 -1px;
            }
          }
          .item {
            &__click {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
            }
            &__image {
              height: (405 * 100vw / 1903);
              padding: 0 (25 * 100vw / 1903);
              align-items: flex-end;
              display: flex;
              @include media($w1024) {
                height: (500 * 100vw / 1903);
                padding: 0 30px;
              }
              @include media($w560) {
                height: (1050 * 100vw / 1903);
              }
              img {
                width: 100%;
                flex: 0 0 100%;
              }
            }
            &__info {
              padding: (50 * 100vw / 1903) (55 * 100vw / 1903);
              @include media($w1024) {
                padding: 25px 30px;
              }
              h2 {
                font-weight: 600;
                margin: 0 0 (14 * 100vw / 1903);
              }
            }
          }
        }
        &__category-descritpion {
          flex: 0 0 100%;
          padding: (80 * 100vw / 1920) 0 0;
          @include media($w1024) {
            padding: 50px 0 0;
          }
          @include media($w1024) {
            padding: 50px 30px 0;
          }
        }
      }
    }
  }
}
