.footer {
  background-color: $white;
  color: rgba($bottom,0.5);
  padding: 0 (70 * 100vw / 1903) (50 * 100vw / 1903);
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
  z-index: 75;
  @include media($w1024) {
    padding: 0 30px 40px;
    flex-wrap: wrap;
  }
  p {
    @include media($w1024) {
      flex: 0 0 100%;
      margin: 0 0 5px;
    }
    a {
      color: rgba($bottom,0.5);
      &:hover {
        color: $red;
      }
    }
    span {
      margin: 0 (5 * 100vw / 1903);
      display: inline-block;
      @include media($w1024) {
        padding: 0 4px;
      }
    }
  }
  & > * {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
