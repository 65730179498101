.page-career {
  .content {
    border-radius: (30 * 100vw / 1903) (30 * 100vw / 1903) 0 0;
    background-color: $white;
    margin: (370 * 100vw / 1903) 0 0;
    padding: (142 * 100vw / 1903) 0 0;
    position: relative;
    z-index: 75;
    @include media($w1024) {
      border-radius: 15px 15px 0 0;
      margin: -15px 0 0;
      padding: 70px 0 0;
    }
    @include media($w768) {
      padding: 50px 0 0;
    }
    &:before {
      content: '';
      background-image: url(../images/career--content--addon.svg);
      background-size: cover;
      background-position: center right;
      width: (862 * 100vw / 1903);
      height: (170 * 100vw / 1903);
      position: absolute;
      top: (42 * 100vw / 1903);
      left: (-70 * 100vw / 1903);
      @include media($w1024) {
        width: 600px;
        height: 118px;
        top: 26px;
        left: -100px;
      }
      @include media($w560) {
        width: 450px;
        height: 89px;
        top: 40px;
        left: -115px;
      }
    }
    &__title {
      padding: 0 0 (130 * 100vw / 1903);
      position: relative;
      @include media($w1024) {
        margin: 0 0 35px;
        padding: 0 0 20px;
      }
      @include media($w768) {
        margin: 0 0 40px;
        padding: 0;
      }
      figure {
        width: 50%;
        position: absolute;
        right: (30 * 100vw / 1903);
        bottom: (80 * 100vw / 1903);
        @include media($w1024) {
          display: none;
        }
        img {
          border-radius: (30 * 100vw / 1903);
          width: 100%;
        }
      }
    }
    &__list {
      padding: 0 0 (30 * 100vw / 1903);
      @include media($w1024) {
        padding: 0 0 30px;
      }
      @include media($w768) {
        padding: 0 0 15px;
      }
      .list {
        &__item {
          & + .list {
            &__item {
              margin: (50 * 100vw / 1903) 0 0;
              @include media($w1024) {
                margin: 40px 0 0;
              }
            }
          }
        }
      }
    }
    &--text {
      padding: (90 * 100vw / 1903) 0 0;
      @include media($w1024) {
        padding: 40px 0 0;
      }
      &:before {
        display: none;
      }
    }
  }
}
