.page-about-us {
  .content {
    .certifications {
      h2 {
        margin: 0 0 (70 * 100vw / 1903);
        @include media($w1024) {
          margin: 0 0 50px;
        }
      }
      ul {
        flex-wrap: wrap;
        display: flex;
        @include media($w1024) {
          margin: 0 0 -10px;
        }
        @include media($w560) {
          margin: 0;
        }
        li {
          @include transition;
          border-radius: (30 * 100vw / 1903);
          flex: 0 0 calc(100% / 2 - (10 * 100vw / 1903));
          margin: 0 (20 * 100vw / 1903) (20 * 100vw / 1903) 0;
          padding: (50 * 100vw / 1903);
          box-shadow: 0 (6 * 100vw / 1903) (12 * 100vw / 1903) 0 rgba($black,0.1);
          @include media($w1024) {
            border-radius: 15px;
            flex: 0 0 calc(100% / 2 - 15px);
            margin: 0 30px 30px 0;
            padding: 30px;
            box-shadow: 0 6px 12px 0 rgba($black,0.1);
          }
          @include media($w560) {
            flex: 0 0 100%;
            margin: 0 0 20px;
          }
          &:nth-child(2n+2) {
            margin: 0 0 (20 * 100vw / 1903);
            @include media($w1024) {
              margin: 0 0 30px;
            }
            @include media($w560) {
              margin: 0 0 20px;
            }
          }
          h3 {
            font-weight: 600;
            margin: 0 0 (55 * 100vw / 1903);
            @include media($w1024) {
              margin: 0 0 32px;
            }
          }
          &:hover {
            box-shadow: 0 (12 * 100vw / 1903) (24 * 100vw / 1903) 0 rgba($black,0.1);
          }
        }
      }
    }
  }
}
