.page-home {
	position: relative;
	z-index: 40;
	
	.slider {
		border-radius: (30 * 100vw / 1903);
		background-image: url('../images/hero/home--hero--bg.jpg');
		background-size: cover;
		background-position: center center;
		width: calc(100% - (34 * 100vw / 1903));
		height: (805 * 100vw / 1903);
		overflow: hidden;
		margin: (158 * 100vw / 1900) (17 * 100vw / 1903) 0;
		@include media($w1024) {
			border-radius: 0 10px 0 10px;
			width: 100%;
			height: 450px;
			margin: 90px 0 0;
		}
		@include media($w768) {
			height: 380px;
		}
		@include media($w560) {
			height: 360px;
			margin: 84px 0 0;
		}
		
		&__slide {
			width: 100%;
			height: 100%;
			
			.slide {
				&__text {
					width: 50%;
					padding: (150 * 100vw / 1920) 0 0 (80 * 100vw / 1920);
					@include media($w1024) {
						padding: 50px 0 0 30px;
					}
					@include media($w768) {
						padding: 35px 0 0 30px;
					}
					@include media($w560) {
						width: 100%;
						padding: 35px 30px 0;
					}
					
					h1 {
						margin: 0 0 (85 * 100vw / 1920);
						position: relative;
						@include media($w1024) {
							margin: 0 0 45px;
						}
						@include media($w768) {
							margin: 0 0 40px;
						}
						@include media($w560) {
							font-size: 30px !important;
							line-height: 36px !important;
						}
						
						&::after {
							content: '';
							background-color: $red;
							width: (70 * 100vw / 1920);
							height: (2 * 100vw / 1920);
							position: absolute;
							bottom: (-45 * 100vw / 1920);
							left: 0;
							@include media($w1024) {
								width: 40px;
								height: 1px;
								bottom: -23px;
							}
							@include media($w768) {
								bottom: -20px;
							}
						}
						
						strong {
							font-weight: 400;
							color: $red;
						}
					}
					
					p {
						max-width: (525 * 100vw / 1920);
						font-size: (24 * 100vw / 1920);
						line-height: 140%;
						@include media($w1024) {
							max-width: 340px;
							font-size: 16px;
							line-height: 130%;
						}
						@include media($w768) {
							max-width: 300px;
							font-size: 14px;
						}
					}
				}
				
				&__image {
					height: 100%;
					position: absolute;
					top: 15%;
					right: 0;
					@include media($w1024) {
						right: -40px;
					}
					@include media($w768) {
						right: -130px;
					}
					@include media($w560) {
						top: 37%;
						right: -120px;
					}
					
					img {
						width: auto;
						//height: 100%;
						height: 78%;
						//@include media($w560) {
						//	height: 78%;
						//}
					}
				}
			}
		}
		
		&__pager {
			align-items: flex-end;
			display: flex;
			position: absolute;
			z-index: 200;
			bottom: (200 * 100vw / 1920);
			left: (80 * 100vw / 1920);
			@include media($w1024) {
				bottom: 100px;
				left: 30px;
			}
			@include media($w768) {
				bottom: 80px;
			}
			@include media($w560) {
				bottom: 35px;
			}
			
			li {
				cursor: pointer;
				
				& + li {
					margin: 0 0 0 (70 * 100vw / 1920);
					@include media($w1024) {
						margin: 0 0 0 35px;
					}
					@include media($w768) {
						margin: 0 0 0 30px;
					}
					@include media($w560) {
						margin: 0 0 0 20px;
					}
				}
				
				img {
					@include transition;
					height: (60 * 100vw / 1920);
					opacity: 0.4;
					transform: scale(1);
					filter: grayscale(1);
					@include media($w1024) {
						height: 34px;
					}
					@include media($w768) {
						height: 30px;
					}
					@include media($w560) {
						height: 26px;
					}
				}
				
				&.active {
					pointer-events: none;
					
					img {
						opacity: 1;
						transform: scale(1.3);
						filter: grayscale(0);
					}
				}
			}
		}
		
		&__link {
			font-size: (14 * 100vw / 1920);
			font-weight: 700;
			line-height: (20 * 100vw / 1920);
			letter-spacing: (2 * 100vw / 1920);
			text-transform: uppercase;
			align-items: center;
			display: inline-flex;
			position: absolute;
			z-index: 200;
			bottom: (65 * 100vw / 1920);
			left: (80 * 100vw / 1920);
			@include media($w1024) {
				font-size: 12px;
				line-height: 18px;
				letter-spacing: 1px;
				bottom: 40px;
				left: 30px;
			}
			@include media($w768) {
				bottom: 30px;
			}
			@include media($w560) {
				display: none;
			}
			
			img {
				width: (42 * 100vw / 1920);
				margin: (2 * 100vw / 1920) (20 * 100vw / 1920) 0 0;
				@include media($w1024) {
					width: 26px;
					margin: 1px 12px 0 0;
				}
			}
		}
	}
}
