.page-home {
  .lab {
    padding: 0 0 (130 * 100vw / 1903);
    @include media($w1024) {
      padding: 0 30px 50px;
    }
    &__copy {
      text-align: center;
      @include media($w768) {
        padding: 0;
      }
      h2 {
        margin: 0 0 (20 * 100vw / 1903);
        @include media($w1024) {
          margin: 0 0 15px;
        }
        span {
          font-weight: 300;
          color: $red;
        }
      }
      & > p {
        margin: 0 0 (55 * 100vw / 1903);
        @include media($w1024) {
          margin: 0 0 40px;
        }
      }
    }
    &__list {
      max-width: (1260 * 100vw / 1903);
      margin: 0 auto (70 * 100vw / 1920);
      justify-content: space-between;
      display: flex;
      position: relative;
      @include media($w1024) {
        max-width: 100%;
        margin: 0 auto 50px;
      }
      @include media($w768) {
        flex-wrap: wrap;
      }
      &::after {
        @include translate;
        content: '';
        background-image: url('../images/icon--qualityCeneterd.svg');
        background-size: cover;
        width: (177 * 100vw / 1920);
        height: (177 * 100vw / 1920);
        position: absolute;
        @include media($w1024) {
          width: 100px;
          height: 100px;
        }
        @include media($w768) {
          display: none;
        }
      }
      .list {
        &__box {
          flex: 0 0 (567 * 100vw / 1920);
          padding: (90 * 100vw / 1920) (50 * 100vw / 1920) (80 * 100vw / 1920);
          position: relative;
          @include media($w1024) {
            flex: 0 0 47.5%;
            padding: 55px 30px 50px;
          }
          @include media($w768) {
            flex: 0 0 100%;
            padding: 55px 0 0 55px;
          }
          @include media($w460) {
            padding: 45px 0 0 55px;
          }
          &::before,
          &::after {
            border: (2 * 100vw / 1920) solid $gray;
            content: '';
            width: 100%;
            height: (27 * 100vw / 1920);
            position: absolute;
            left: 0;
            @include media($w1024) {
              border-width: 1px;
              height: 20px;
            }
          }
          &::before {
            border-bottom: 0;
            border-radius: (27 * 100vw / 1920) (27 * 100vw / 1920) 0 0;
            top: 0;
            @include media($w1024) {
              border-radius: 20px 20px 0 0;
            }
          }
          &::after {
            border-top: 0;
            border-radius: 0 0 (27 * 100vw / 1920) (27 * 100vw / 1920);
            bottom: 0;
            @include media($w1024) {
              border-radius: 0 0 20px 20px;
            }
            @include media($w768) {
              display: none;
            }
          }
          .box {
            &__item {
              width: (370 * 100vw / 1920);
              align-items: center;
              display: flex;
              position: relative;
              @include media($w1024) {
                width: 80%;
              }
              @include media($w768) {
                width: 100%;
              }
              &::before,
              &::after {
                @include translateY;
                content: '';
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
                width: (43 * 100vw / 1903);
                height: (43 * 100vw / 1903);
                position: absolute;
                @include media($w1024) {
                  width: 30px;
                  height: 30px;
                }
              }
              &::before {
                left: (-80 * 100vw / 1920);
                @include media($w1024) {
                  left: -43px;
                }
                @include media($w768) {
                  left: -55px;
                }
              }
              &::after {
                right: (-80 * 100vw / 1920);
                @include media($w1024) {
                  right: -43px;
                }
                @include media($w768) {
                  right: auto;
                  left: -55px;
                }
              }
              & + .box {
                &__item {
                  margin: (60 * 100vw / 1920) 0 0;
                  @include media($w1024) {
                    margin: 30px 0 0;
                  }
                }
              }
              span {
                flex: 0 0 (55 * 100vw / 1920);
                font-size: (60 * 100vw / 1920);
                font-weight: 700;
                line-height: (60 * 100vw / 1920);
                padding: 0 0 (5 * 100vw / 1920);
                @include media($w1024) {
                  flex: 0 0 40px;
                  font-size: 30px;
                  line-height: 30px;
                  padding: 0 0 2px;
                }
                @include media($w768) {
                  flex: 0 0 35px;
                }
              }
              &--first {
                &::before {
                  background-image: url(../images/home-lab--icon-01.svg);
                }
                &::after {
                  display: none;
                }
              }
              &--second {
                &::before {
                  display: none;
                }
                &::after {
                  background-image: url(../images/home-lab--icon-02.svg);
                }
              }
              &--third {
                &::before {
                  background-image: url(../images/home-lab--icon-03.svg);
                }
                &::after {
                  display: none;
                }
              }
              &--forth {
                &::before {
                  display: none;
                }
                &::after {
                  background-image: url(../images/home-lab--icon-04.svg);
                }
              }
            }
          }
          & + .list {
            &__box {
              @include media($w768) {
                flex: 0 0 100%;
                margin: 30px 0 0;
                padding: 0 0 50px 55px;
              }
              @include media($w460) {
                padding: 0 0 45px 55px;
              }
              &::before {
                @include media($w768) {
                  display: none;
                }
              }
              &::after {
                @include media($w768) {
                  display: block;
                }
              }
              .box {
                &__item {
                  margin: 0 0 0 auto;
                  @include media($w768) {
                    margin: 0;
                  }
                  & + .box {
                    &__item {
                      margin: (60 * 100vw / 1920) 0 0 auto;
                      @include media($w1024) {
                        margin: 30px 0 0 auto;
                      }
                      @include media($w768) {
                        margin: 30px 0 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      ol {
        margin: (-25 * 100vw / 1903) 0 (37 * 100vw / 1903);
        counter-reset: number-point;
        @include media($w1024) {
          margin: -20px 0 20px;
        }
        li {
          padding: (25 * 100vw / 1903) 0 (25 * 100vw / 1903) (130 * 100vw / 1903);
          @include media($w1024) {
            padding: 20px 0 20px 90px;
          }
          &:before {
            @include translateY;
            content: counter(number-point);
            counter-increment: number-point;
            width: (65 * 100vw / 1903);
            font-size: (73 * 100vw / 1903);
            font-weight: 700;
            line-height: (76 * 100vw / 1903);
            text-align: center;
            position: absolute;
            top: calc(50% - (4 * 100vw / 1903));
            left: (60 * 100vw / 1903);
            @include media($w1024) {
              width: 50px;
              font-size: 48px;
              line-height: 56px;
              top: calc(50% - 3px);
              left: 35px;
            }
          }
          &:after {
            @include translateY;
            content: '';
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            width: (43 * 100vw / 1903);
            height: (43 * 100vw / 1903);
            position: absolute;
            top: calc(50% + (3 * 100vw / 1903));
            left: 0;
            @include media($w1024) {
              width: 30px;
              height: 30px;
              top: calc(50% + 2px);
            }
          }
          &:nth-child(1) {
            &:after {
              background-image: url(../images/home-lab--icon-01.svg);
            }
          }
          &:nth-child(2) {
            &:after {
              background-image: url(../images/home-lab--icon-02.svg);
            }
          }
          &:nth-child(3) {
            &:after {
              background-image: url(../images/home-lab--icon-03.svg);
            }
          }
          &:nth-child(4) {
            &:after {
              background-image: url(../images/home-lab--icon-04.svg);
            }
          }
          p {
            max-width: (310 * 100vw / 1903);
            @include media($w1024) {
              max-width: none;
            }
          }
        }
      }
      &--desktop {
        @include media($w768) {
          display: none;
        }
      }
      &--mobile {
        display: none;
        @include media($w768) {
          display: flex;
        }
      }
    }
    &__button {
      text-align: center;
    }
  }
}
