.page-product {
  .content {
    border-radius: (30 * 100vw / 1903) (30 * 100vw / 1903) 0 0;
    background-color: $white;
    padding: (260 * 100vw / 1903) 0 (100 * 100vw / 1903);
    position: relative;
    z-index: 50;
    @include media($w1024) {
      border-radius: 15px 15px 0 0;
      margin: -15px 0 0;
      padding: 120px 0 60px;
    }
    @include media($w768) {
      padding: 95px 0 40px;
    }
    @include media($w560) {
      padding: 95px 0 35px;
    }
    &__image {
      @include translate;
      width: (1200 * 100vw / 1903);
      height: (520 * 100vw / 1903);
      pointer-events: none;
      justify-content: center;
      align-items: center;
      display: flex;
      position: absolute;
      top: 0;
      @include media($w1024) {
        width: 100%;
        height: 270px;
      }
      @include media($w768) {
        height: 230px;
      }
      @include media($w560) {
        height: 200px;
      }
      img {
        max-width: 100%;
        max-height: 100%;
        display: block;
      }
    }
    &__nav {
      border-radius: (33 * 100vw / 1903);
      background-color: $white;
      width: (132 * 100vw / 1903);
      height: (66 * 100vw / 1903);
      padding: 0 (25 * 100vw / 1903);
      justify-content: space-between;
      align-items: center;
      display: flex;
      box-shadow: 0 (5 * 100vw / 1903) (20 * 100vw / 1903) 0 rgba($black,0.1);
      position: absolute;
      top: (-150 * 100vw / 1903);
      right: (30 * 100vw / 1903);
      @include media($w1024) {
        border-radius: 20px;
        width: 80px;
        height: 40px;
        padding: 0 15px;
        box-shadow: 0 5px 20px 0 rgba($black,0.1);
        top: -140px;
        right: 30px;
      }
      @include media($w768) {
        top: -115px;
      }
      @include media($w560) {
        display: none;
      }
      .nav {
        &__prev,
        &__next {
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          width: (24 * 100vw / 1903);
          height: (60 * 100vw / 1903);
          cursor: pointer;
          position: relative;
          @include media($w1024) {
            width: 16px;
            height: 36px;
          }
          &:before {
            @include transition;
            content: '';
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            width: 100%;
            height: 100%;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
          }
          &:hover {
            &:before {
              opacity: 1;
            }
          }
        }
        &__prev {
          background-image: url(../images/icon--technology-slider--prev--gray.svg);
          &:before {
            background-image: url(../images/icon--technology-slider--prev--red.svg);
          }
        }
        &__next {
          background-image: url(../images/icon--technology-slider--next--gray.svg);
          &:before {
            background-image: url(../images/icon--technology-slider--next--red.svg);
          }
        }
      }
    }
    &__main {
      padding: (80 * 100vw / 1920) 0 0;
      @include media($w1024) {
        padding: 50px 0 0;
      }
      @include media($w560) {
        padding: 40px 0 0;
      }
      .main {
        &__desc {
          margin: 0 0 (90 * 100vw / 1920);
          display: flex;
          @include media($w1024) {
            margin: 0 0 50px;
          }
          @include media($w560) {
            margin: 0 0 40px;
            display: block;
          }
          h2 {
            margin: 0 (80 * 100vw / 1920) 0 0;
            @include media($w1024) {
              margin: 0 25px 0 0;
            }
            @include media($w560) {
              margin: 0 0 25px;
            }
          }
          .desc__text {
            flex: 1 1 auto;
            .c {
              margin: 0 0 (40 * 100vw / 1920);
              @include media($w1024) {
                margin: 0 0 25px;
              }
            }
          }
        }
        &__lists {
          margin: 0 0 (90 * 100vw / 1920);
          display: flex;
          @include media($w1024) {
            margin: 0 0 50px;
          }
          @include media($w768) {
            width: calc(100% + 60px);
            margin: 0 -30px 50px;
            display: block;
          }
          @include media($w560) {
            margin: 0 -30px 40px;
          }
          .lists {
            &__left,
            &__right {
              flex: 0 0 50%;
              h2 {
                margin: 0 0 (65 * 100vw / 1920);
                @include media($w1024) {
                  margin: 0 0 25px;
                }
              }
            }
            &__left {
              padding: (45 * 100vw / 1920) (80 * 100vw / 1920) (90 * 100vw / 1920) 0;
              @include media($w1024) {
                padding: 30px 30px 30px 0;
              }
              @include media($w768) {
                padding: 0 30px 35px;
              }
              ul {
                li {
                  font-weight: 700;
                  padding: 0 0 0 (45 * 100vw / 1920);
                  position: relative;
                  @include media($w1024) {
                    padding: 0 0 0 35px;
                  }
                  &::before {
                    @include translateY;
                    content: '';
                    background-image: url('../images/product/icon--list-01.svg');
                    background-size: cover;
                    width: (27 * 100vw / 1920);
                    height: (27 * 100vw / 1920);
                    position: absolute;
                    top: calc(50% + (3 * 100vw / 1920));
                    left: 0;
                    @include media($w1024) {
                      width: 18px;
                      height: 18px;
                      top: calc(50% + 2px);
                    }
                  }
                  & + li {
                    margin: (55 * 100vw / 1920) 0 0;
                    @include media($w1024) {
                      margin: 20px 0 0;
                    }
                  }
                }
              }
            }
            &__right {
              border-radius: (10 * 100vw / 1920);
              background-color: rgba($gray, 0.5);
              padding: (45 * 100vw / 1920) (80 * 100vw / 1920) (90 * 100vw / 1920);
              @include media($w1024) {
                padding: 30px;
              }
              @include media($w768) {
                border-radius: 0;
                padding: 25px 30px;
              }
              ul {
                padding: (10 * 100vw / 1920) (20 * 100vw / 1920);
                @include media($w1024) {
                  padding: 0;
                }
                li {
                  font-weight: 700;
                  align-items: center;
                  display: flex;
                  & + li {
                    margin: (60 * 100vw / 1920) 0 0;
                    @include media($w1024) {
                      margin: 20px 0 0;
                    }
                  }
                  figure {
                    flex: 0 0 (50 * 100vw / 1920);
                    margin: 0 (30 * 100vw / 1920) 0 0;
                    padding: (6 * 100vw / 1920) 0 0;
                    @include media($w1024) {
                      flex: 0 0 30px;
                      margin: 0 20px 0 0;
                      padding: 3px 0 0;
                    }
                    img {
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
        &__order {
          border: (12 * 100vw / 1920) solid rgba($gray, 0.5);
          border-radius: (5 * 100vw / 1920);
          padding: (40 * 100vw / 1920) (30 * 100vw / 1920) (40 * 100vw / 1920) (40 * 100vw / 1920);
          align-items: center;
          display: flex;
          @include media($w1024) {
            border-width: 8px;
            border-radius: 5px;
            padding: 23px 25px 25px;
          }
          @include media($w560) {
            border-left: 0;
            border-right: 0;
            width: calc(100% + 60px);
            margin: 0 -30px;
            padding: 25px 30px;
            display: block;
          }
          figure {
            border-right: (1 * 100vw / 1920) solid rgba($gray, 0.5);
            flex: 0 0 (112 * 100vw / 1920);
            margin: 0 (30 * 100vw / 1920) 0 0;
            padding: 0 (30 * 100vw / 1920) 0 0;
            @include media($w1024) {
              border-right-width: 1px;
              flex: 0 0 90px;
              margin: 0 25px 0 0;
              padding: 0 25px 0 0;
            }
            @include media($w560) {
              border: 0;
              width: 60px;
              margin: 0 0 15px;
              padding: 0;
            }
            img {
              width: 100%;
            }
          }
          .order {
            &__text {
              h3 {
                color: $red;
                margin: 0 0 (15 * 100vw / 1920);
                @include media($w1024) {
            			margin: 0 0 10px;
            		}
              }
              p {
                font-size: (17 * 100vw / 1920);
                line-height: 140%;
                text-align: justify;
                @include media($w1024) {
            			font-size: 14px;
                  text-align: left;
            		}
              }
            }
          }
        }
      }
    }
  }
}
