/* Style the header: fixed position (always stay at the top) */
.progress-bar {
	position: fixed;
	top: 0;
	z-index: 9999;
	width: 100%;
	background-color: #f1f1f1;
	
	&__container {
		width: 100%;
		height: 8px;
		background: #ccc;
		
		&__line {
			height: 8px;
			background: #d41f28;
			width: 0%;
		}
	}
}

.wpcf7-response-output {
	color: #434343 !important;
}
