.cookies {
  background-color: $white;
  width: 100%;
  padding: (22 * 100vw / 1903) (70 * 100vw / 1903);
  display: none;
  box-shadow: 0 (5 * 100vw / 1903) (30 * 100vw / 1903) 0 rgba($black,0.1);
  position: fixed;
  z-index: 99999999999;
  bottom: 0;
  left: 0;
  @include media($w1024) {
    padding: 20px 30px 25px;
    box-shadow: 0 5px 20px 0 rgba($black,0.1);
  }
  &__inner {
    justify-content: space-between;
    align-items: center;
    display: flex;
    @include media($w1024) {
      flex-wrap: wrap;
    }
    p {
      @include media($w1024) {
        flex: 0 0 100%;
        text-align: center;
        margin: 0 0 20px;
      }
    }
    .inner {
      &__buttons {
        justify-content: flex-end;
        align-items: center;
        display: flex;
        @include media($w1024) {
          flex: 0 0 100%;
          justify-content: center;
        }
        .btn {
          & + .btn {
            margin: 0 0 0 (15 * 100vw / 1903);
          }
        }
      }
    }
  }
}
