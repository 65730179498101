.btn {
	display: inline-block;
	span {
		@include transition;
		border: (3 * 100vw / 1903) solid $red;
		border-radius: (26 * 100vw / 1903);
		background-color: transparent;
		height: (52 * 100vw / 1903);
		font-size: (16 * 100vw / 1903);
		font-weight: 700;
		line-height: (22 * 100vw / 1903);
		text-transform: uppercase;
		letter-spacing: 0.2em;
		color: $red;
		margin: 0;
		padding: 0 (65 * 100vw / 1903) (1 * 100vw / 1903) (32 * 100vw / 1903);
		align-items: center;
		display: flex;
		position: relative;
		@include media($w1024) {
			border-width: 2px;
			border-radius: 20px;
			height: 40px;
			font-size: 13px;
			line-height: 19px;
			letter-spacing: 0.1em;
			padding: 0 40px 2px 20px;
		}
		@include media($w560) {
			letter-spacing: 0;
			padding: 0 38px 2px 18px;
		}
		&:before,
		&:after {
			@include transition;
			@include translateY;
			content: '';
			background-image: url(../images/icon--btn-arrow--red.svg);
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center center;
			width: (22 * 100vw / 1903);
			height: (22 * 100vw / 1903);
			pointer-events: none;
			position: absolute;
			right: (22 * 100vw / 1903);
			@include media($w1024) {
				width: 15px;
				height: 15px;
				right: 15px;
			}
		}
		&:after {
			background-image: url(../images/icon--btn-arrow--white.svg);
			opacity: 0;
		}
	}
	&:hover {
		span {
			background-color: $red;
			color: $white;
			&:before {
				opacity: 0;
			}
			&:after {
				opacity: 1;
			}
		}
	}
	&--black {
		span {
			border-color: $black;
			color: $black;
			&:before,
			&:after {
				background-image: url(../images/icon--btn-arrow--black.svg);
			}
			&:after {
				background-image: url(../images/icon--btn-arrow--white.svg);
			}
		}
		&:hover {
			span {
				background-color: $black;
				color: $white;
			}
		}
	}
	&--white {
		span {
			border-color: $white;
			background-color: $white;
			color: $black;
			&:before,
			&:after {
				background-image: url(../images/icon--btn-arrow--black.svg);
			}
			&:after {
				background-image: url(../images/icon--btn-arrow--white.svg);
			}
		}
		&:hover {
			span {
				background-color: transparent;
				color: $white;
			}
		}
		&--red {
			span {
				color: $red;
				&:before,
				&:after {
					background-image: url(../images/icon--btn-arrow--red.svg);
				}
				&:after {
					background-image: url(../images/icon--btn-arrow--white.svg);
				}
			}
		}
	}
	&--fullRed {
		span {
			background-color: $red;
			color: $white;
		}
		&:hover {
			span {
				border-color: $black;
				background-color: $black;
				color: $white;
			}
		}
	}
	&--white-border {
		span {
			border-color: $white;
			color: $white;
			&:before,
			&:after {
				background-image: url(../images/icon--btn-arrow--white.svg);
			}
			&:after {
				background-image: url(../images/icon--btn-arrow--black.svg);
			}
		}
		&:hover {
			span {
				background-color: $white;
				color: $black;
			}
		}
	}
	&--gray {
		span {
			border-color: $bottom;
			color: $bottom;
		}
		&:hover {
			span {
				background-color: $bottom;
				color: $white;
			}
		}
	}
	&--no-icon {
		span {
			padding: 0 (32 * 100vw / 1903) (1 * 100vw / 1903);
			@include media($w1024) {
				padding: 0 20px 1px;
			}
			&:before,
			&:after {
				display: none;
			}
		}
	}
	&--small {
		span {
			border-width: (2 * 100vw / 1903);
			border-radius: (17 * 100vw / 1903);
			height: (34 * 100vw / 1903);
			font-size: (15 * 100vw / 1903);
			font-weight: 600;
			line-height: (12 * 100vw / 1903);
			letter-spacing: 0.02em;
			text-transform: none;
			padding: 0 (20 * 100vw / 1903) (2 * 100vw / 1903);
			@include media($w1024) {
				border-width: 2px;
				border-radius: 15px;
				height: 30px;
				font-size: 14px;
				line-height: 14px;
				padding: 0 20px 2px;
			}
			@include media($w1024) {
				border-width: 1px;
				border-radius: 14px;
				height: 28px;
				font-size: 13px;
				line-height: 13px;
				padding: 0 15px 2px;
			}
		}
	}
}
