.page-about-us {
  .content {
    .copy {
      padding: 0 0 (130 * 100vw / 1903);
      @include media($w1024) {
        padding: 0;
      }
      h2 {
        margin: 0 0 (70 * 100vw / 1903);
        @include media($w1024) {
          margin: 0 0 35px;
        }
      }
      p {
        max-width: (1020 * 100vw / 1903);
        margin: 0 0 (20 * 100vw / 1903);
        @include media($w1024) {
          max-width: 70%;
          margin: 0 0 15px;
        }
        @include media($w768) {
          max-width: 100%;
        }
      }
      & > * {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
