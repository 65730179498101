.page-career {
  .hero {
    background-color: $red;
    width: 100%;
    height: (400 * 100vw / 1903);
    color: $white;
    padding: 0 (110 * 100vw / 1903) (100 * 100vw / 1903) (80 * 100vw / 1903);
    justify-content: space-between;
    align-items: flex-end;
    display: flex;
    position: fixed;
    z-index: 25;
    top: 0;
    left: 0;
    @include media($w1024) {
      height: auto;
      padding: 100px 30px 55px;
      position: relative;
      top: auto;
      left: auto;
    }
  }
}
