.page-home {
  .technology {
    padding: 0 0 (150 * 100vw / 1903);
    @include media($w1024) {
      padding: 0 0 40px;
    }
    @include media($w560) {
      padding: 0 0 120px;
    }
    &__copy {
      text-align: center;
      margin: 0 0 (55 * 100vw / 1903);
      @include media($w1024) {
        margin: 0 0 30px;
      }
      h2 {
        margin: 0 0 (45 * 100vw / 1903);
        @include media($w1024) {
          margin: 0 0 20px;
        }
      }
      p {
        max-width: (485 * 100vw / 1903);
        margin: 0 auto;
        @include media($w1024) {
          max-width: 600px;
        }
      }
    }
    &__tabs {
      position: relative;
      @include media($w560) {
        width: calc(100% + 60px);
        margin: 0 -30px;
      }
      &:after {
        @include translateY;
        content: '';
        background-image: url(../images/home-technology-slider--addon.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        width: (204 * 100vw / 1903);
        height: (222 * 100vw / 1903);
        position: absolute;
        z-index: -25;
        top: calc(50% + (57.5 * 100vw / 1903));
        left: (-180 * 100vw / 1903);
        @include media($w1024) {
          display: none;
        }
      }
      .tabs {
        &__slide {
          width: 100%;
          .slide {
            &__inner {
              padding: (115 * 100vw / 1903) 0 0;
              align-items: flex-end;
              display: flex;
              @include media($w1024) {
                padding: 70px 0 0;
              }
              @include media($w768) {
                padding: 55px 0 0;
              }
            }
            &__image {
              border-radius: (30 * 100vw / 1903);
              background-size: cover;
              background-position: center center;
              flex: (775 * 100vw / 1903);
              height: (720 * 100vw / 1903);
              @include media($w1024) {
                border-radius: 15px;
                flex: 0 0 60%;
                height: 500px;
              }
              @include media($w768) {
                flex: 0 0 calc(100% - 40px);
                height: 450px;
              }
              @include media($w560) {
                border-radius: 15px 0 15px 0;
                flex: 0 0 100%;
              }
            }
            &__copy {
              border-radius: (30 * 100vw / 1093) 0 (30 * 100vw / 1093) 0;
              background-color: $white;
              flex: 0 0 calc(100% - (675 * 100vw / 1903));
              margin: 0 0 0 (-100 * 100vw / 1903);
              padding: (55 * 100vw / 1903) (55 * 100vw / 1903) (60 * 100vw / 1903);
              box-shadow: 0 (20 * 100vw / 1903) (40 * 100vw / 1903) 0 rgba($black,0.1);
              position: relative;
              @include media($w1024) {
                border-radius: 15px 0 15px 0;
                flex: 0 0 calc(40% + 30px);
                margin: 0 0 0 -30px;
                padding: 25px 30px 30px;
                box-shadow: 0 10px 20px 0 rgba($black,0.1);
              }
              @include media($w768) {
                flex: 0 0 300px;
                margin: 0 0 0 -260px;
              }
              @include media($w560) {
                flex: 0 0 100%;
                margin: 0 0 -80px -100%;
              }
              h3 {
                font-weight: 600;
                margin: 0 0 (35 * 100vw / 1903);
                @include media($w1024) {
                  margin: 0 0 15px;
                }
              }
              p {
                margin: 0 0 (45 * 100vw / 1903);
                @include media($w1024) {
                  margin: 0 0 20px;
                }
              }
            }
          }
          &:nth-child(3) {
            .tabs {
              &__nav {
                .nav {
                  &__prev {
                    background-image: url(../images/icon--technology-slider--prev--gray.svg);
                    pointer-events: none;
                  }
                }
              }
            }
          }
          &:last-of-type {
            .tabs {
              &__nav {
                .nav {
                  &__next {
                    background-image: url(../images/icon--technology-slider--next--gray.svg);
                    pointer-events: none;
                  }
                }
              }
            }
          }
        }
        &__dots {
          width: 100%;
          justify-content: center;
          display: flex;
          position: absolute;
          z-index: 200;
          top: 0;
          left: 0;
          li {
            @include transition;
            border: (3 * 100vw / 1903) solid $gray;
            border-radius: (26 * 100vw / 1903);
            font-size: (14 * 100vw / 1903);
            font-weight: 600;
            line-height: (40 * 100vw / 1903);
            cursor: pointer;
            padding: 0 (28 * 100vw / 1903) (3 * 100vw / 1903);
            @include media($w1024) {
              border-width: 2px;
              border-radius: 16px;
              flex: 0 0 20px;
              height: 20px;
              padding: 0;
            }
            & + li {
              margin: 0 0 0 (20 * 100vw / 1903);
              @include media($w1024) {
                margin: 0 0 0 12px;
              }
            }
            span {
              @include media($w1024) {
                display: none;
              }
            }
            &.active {
              border-color: $red;
              color: $red;
            }
          }
        }
        &__nav {
          border-radius: (33 * 100vw / 1903);
          background-color: $white;
          width: (132 * 100vw / 1903);
          height: (66 * 100vw / 1903);
          padding: 0 (25 * 100vw / 1903);
          justify-content: space-between;
          align-items: center;
          display: flex;
          box-shadow: 0 (5 * 100vw / 1903) (20 * 100vw / 1903) 0 rgba($black,0.1);
          position: absolute;
          top: (-115 * 100vw / 1903);
          right: 0;
          @include media($w1024) {
            border-radius: 20px;
            width: 80px;
            height: 40px;
            padding: 0 15px;
            box-shadow: 0 5px 20px 0 rgba($black,0.1);
            top: -60px;
          }
          @include media($w560) {
            display: none;
          }
          .nav {
            &__prev,
            &__next {
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
              width: (24 * 100vw / 1903);
              height: (60 * 100vw / 1903);
              cursor: pointer;
              @include media($w1024) {
                width: 16px;
                height: 36px;
              }
            }
            &__prev {
              background-image: url(../images/icon--technology-slider--prev--red.svg);
            }
            &__next {
              background-image: url(../images/icon--technology-slider--next--red.svg);
            }
          }
        }
      }
    }
  }
}
