.page-products {
  .hero {
    background-color: $red;
    width: 100%;
    color: $white;
    padding: (220 * 100vw / 1903) 0 (120 * 100vw / 1903);
    position: fixed;
    z-index: 25;
    top: 0;
    left: 0;
    @include media($w1024) {
      padding: 100px 0 60px;
      position: relative;
      top: auto;
      left: auto;
    }
    &:before {
      content: '';
      background-image: url(../images/products--hero--addon.svg);
      background-size: cover;
      background-position: center right;
      width: (862 * 100vw / 1903);
      height: (170 * 100vw / 1903);
      position: absolute;
      top: (180 * 100vw / 1903);
      left: (-70 * 100vw / 1903);
      @include media($w1024) {
        display: none;
      }
    }
    a {
      color: $white;
    }
    &__title {
      padding: 0 (350 * 100vw / 1903) 0 (80 * 100vw / 1903);
      justify-content: space-between;
      align-items: center;
      display: flex;
      position: relative;
      z-index: 25;
      @include media($w1024) {
        padding: 0 30px;
      }
      @include media($w560) {
        flex-wrap: wrap;
      }
      h1 {
        flex: 0 0 calc(100% - (250 * 100vw / 1903));
        @include media($w1024) {
          flex: 0 0 calc(100% - 170px);
        }
        @include media($w560) {
          flex: 0 0 100%;
          margin: 0 0 25px;
        }
      }
      .title {
        &__switcher {
          font-size: (20 * 100vw / 1903);
          font-weight: 600;
          line-height: (26 * 100vw / 1903);
          cursor: pointer;
          padding: (5 * 100vw / 1903) (35 * 100vw / 1903) 0 0;
          position: relative;
          @include media($w1024) {
            font-size: 14px;
            line-height: 20px;
            padding: 3px 20px 0 0;
          }
          &:after {
            @include transition;
            content: '';
            background-image: url(../images/icon--dropdown--white.svg);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            width: (16 * 100vw / 1903);
            height: (16 * 100vw / 1903);
            position: absolute;
            top: calc(50% - (4 * 100vw / 1903));
            right: 0;
            @include media($w1024) {
              width: 10px;
              height: 10px;
              top: calc(50% - 2px);
            }
          }
          &.open {
            &:after {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
    &__categories {
      text-align: right;
      padding: (40 * 100vw / 1903) (350 * 100vw / 1903) (30 * 100vw / 1903) (80 * 100vw / 1903);
      display: none;
      position: relative;
      z-index: 25;
      @include media($w1024) {
        padding: 25px 30px 5px;
      }
      @include media($w560) {
        text-align: left;
        padding: 28px 30px 0;
      }
      ul {
        margin: 0 0 (90 * 100vw / 1903);
        @include media($w1024) {
          margin: 0 0 35px;
        }
        @include media($w560) {
          margin: 0 0 28px;
        }
        li {
          font-size: (36 * 109vw / 1903);
          font-weight: 600;
          line-height: (42 * 100vw / 1903);
          @include media($w1024) {
            font-size: 20px;
            line-height: 26px;
          }
          @include media($w560) {
            font-size: 18px;
            line-height: 24px;
          }
          & + li {
            margin: (25 * 100vw / 1903) 0 0;
            @include media($w1024) {
              margin: 12px 0 0;
            }
            @include media($w560) {
              margin: 10px 0 0;
            }
          }
        }
      }
      .categories {
        &__label {
          font-size: (20 * 100vw / 1903);
          font-weight: 600;
          line-height: (26 * 100vw / 1903);
          @include media($w1024) {
            font-size: 14px;
            line-height: 20px;
          }
          @include media($w560) {
            margin: 0 0 -5px;
          }
        }
      }
    }
  }
}
