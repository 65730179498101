input[type="text"],
input[type="tel"],
input[type="email"],
textarea {
	border: 0;
	border-bottom: (2 * 100vw / 1903) solid $white;
	border-radius: 0;
	background-color: transparent;
	width: 100%;
	height: (42 * 100vw / 1903);
	font-family: 'acumin-pro' !important;
	font-size: (15 * 100vw / 1903);
	line-height: (20 * 100vw / 1903);
	color: $white;
	outline: none;
	margin: 0 0 (15 * 100vw / 1903);
	padding: 0 0 (2 * 100vw / 1903);
	box-shadow: none;
	-webkit-appearance: none;
	transition: border-color 0.4s ease 0s, background-color 0.4s ease 0s, color 0.4s ease 0s;
	@include media($w1024) {
		border-bottom-width: 2px;
		height: 38px;
		font-size: 14px;
		line-height: 20px;
		margin: 0 0 12px;
		padding: 0 0 2px;
	}
	&:focus {
		border-color: $white;
		background-color: transparent;
	}
}

input[type="submit"] {
	@include transition;
	border: (3 * 100vw / 1903) solid $white !important;
	border-radius: (26 * 100vw / 1903) !important;
	background-color: transparent !important;
	width: auto !important;
	height: (52 * 100vw / 1903);
	min-height: none !important;
	font-family: 'acumin-pro' !important;
	font-size: (16 * 100vw / 1903) !important;
	font-weight: 700 !important;
	line-height: (22 * 100vw / 1903);
	text-transform: uppercase;
	letter-spacing: 0.2em;
	color: $white !important;
	cursor: pointer;
	margin: 0;
	padding: 0 (65 * 100vw / 1903) (2 * 100vw / 1903) (32 * 100vw / 1903);
	@include media($w1024) {
		border-width: 2px !important;
		border-radius: 20px !important;
		height: 40px !important;
		font-size: 13px !important;
		line-height: 19px !important;
		letter-spacing: 0.1em !important;
		padding: 0 40px 1px 20px !important;
	}
	&:hover {
		background-color: $white !important;
		color: $red !important;
	}
}

textarea {
	height: 100px;
	resize: none;
	margin: 0 0 (35 * 100vw / 1903);
	padding: (10 * 100vw / 1903) 0 0;
	@include media($w1024) {
		margin: 0 0 25px;
		padding: 7px 0 0;
	}
}

::-webkit-input-placeholder {
	color: $white !important;
	opacity: 1;
}

:-moz-placeholder {
	color: $white !important;
	opacity: 1;
}

::-moz-placeholder {
	color: $white !important;
	opacity: 1;
}

:-ms-input-placeholder {
	color: $white !important;
	opacity: 1;
}

button {
	@include transition;
	border: 0;
	background-color: transparent;
	font-family: 'acumin-pro' !important;
	cursor: pointer;
	margin: 0;
	-webkit-appearance: none;
}
