.page-about-us {
  .hero {
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: (400 * 100vw / 1903);
    color: $white;
    padding: 0 (110 * 100vw / 1903) (100 * 100vw / 1903) (80 * 100vw / 1903);
    justify-content: space-between;
    align-items: flex-end;
    display: flex;
    position: fixed;
    z-index: 25;
    top: 0;
    left: 0;
    @include media($w1024) {
      height: 195px;
      padding: 0 30px 55px;
      position: relative;
      top: 0;
      left: 0;
    }
    &__motto {
      flex: 0 0 (216 * 100vw / 1903);
      padding: 0 0 (22 * 100vw / 1903);
      @include media($w1024) {
        flex: 0 0 200px;
        padding: 0 0 12px;
      }
      @include media($w560) {
        flex: 0 0 180px;
      }
      img {
        width: 100%;
      }
    }
  }
}
