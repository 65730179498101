.page-slider {
	height: (965 * 100vw / 1903);
	color: $white;
	z-index: 25 !important;
	@include media($w1024) {
		height: auto;
	}
	
	&__title {
		position: absolute;
		z-index: 200;
		top: (220 * 100vw / 1903);
		left: (80 * 100vw / 1903);
		@include media($w1024) {
			top: 105px;
			left: 30px;
		}
	}
	
	&__container {
		z-index: 50;
		position: relative;
		padding: (360 * 100vw / 1903) (175 * 100vw / 1903) 0 0;
		justify-content: flex-end;
		align-items: flex-start;
		display: flex !important;
		@include media($w1024) {
			height: auto;
			min-height: 100%;
			padding: 185px 94px 70px 0;
		}
		@include media($w560) {
			padding: 185px 30px 70px;
		}
	}
	
	&__slide {
		background-size: cover;
		background-position: center center;
		width: 100%;
		height: 100%;
		//padding: (360 * 100vw / 1903) (175 * 100vw / 1903) 0 0;
		//justify-content: flex-end;
		//align-items: flex-start;
		//display: flex !important;
		//@include media($w1024) {
		//	height: auto;
		//	min-height: 100%;
		//	padding: 185px 94px 70px 0;
		//}
		//@include media($w560) {
		//	padding: 185px 30px 70px;
		//}
		
		@media (max-width: 767px) {
			&:before {
				content: '';
				background-color: rgba($black, 0.08);
				width: 100%;
				height: 100%;
				position: absolute;
				z-index: 25;
				top: 0;
				left: 0;
			}
		}
		
		&:nth-child(3) {
			.page-slider {
				&__addon {
					background-image: url(../images/services--addon-01.svg);
				}
				
				&__number {
					background-image: url(../images/services--number-01.svg);
				}
			}
		}
		
		&:nth-child(4) {
			.page-slider {
				&__addon {
					background-image: url(../images/services--addon-02.svg);
				}
				
				&__number {
					background-image: url(../images/services--number-02.svg);
				}
			}
		}
		
		&:nth-child(5) {
			.page-slider {
				&__addon {
					background-image: url(../images/services--addon-03.svg);
				}
				
				&__number {
					background-image: url(../images/services--number-03.svg);
				}
			}
		}
	}
	
	&__addon {
		background-size: cover;
		background-position: center right;
		width: (862 * 100vw / 1903);
		height: (170 * 100vw / 1903);
		position: absolute;
		z-index: 50;
		top: (355 * 100vw / 1903);
		left: (-30 * 100vw / 1903);
		@include media($w1024) {
			width: 600px;
			height: 118px;
			top: 169px;
			left: -50px;
		}
	}
	
	&__copy {
		flex: 0 0 (775 * 100vw / 1903);
		position: relative;
		z-index: 50;
		@include media($w1024) {
			flex: 0 0 50%;
		}
		@include media($w768) {
			flex: 0 0 calc(100% - 165px);
		}
		@include media($w560) {
			flex: 0 0 100%;
		}
		
		h2 {
			margin: 0 0 (45 * 100vw / 1903);
			@include media($w1024) {
				margin: 0 0 20px;
			}
		}
		
		p {
			font-size: (20 * 100vw / 1903);
			line-height: (24 * 100vw / 1903);
			margin: 0 0 (20 * 100vw / 1903);
			@include media($w1024) {
				font-size: 14px;
				line-height: 20px;
			}
		}
		
		ul {
			margin: 0 0 (20 * 100vw / 1903);
			@include media($w1024) {
				margin: 0 0 15px;
			}
			
			li {
				font-size: (20 * 100vw / 1903);
				line-height: (24 * 100vw / 1903);
				padding: 0 0 0 (30 * 100vw / 1903);
				@include media($w1024) {
					font-size: 14px;
					line-height: 20px;
					padding: 0 0 0 18px;
				}
				
				&:before {
					content: '';
					background-image: url(../images/services--icon-list.svg);
					background-size: contain;
					background-repeat: no-repeat;
					background-position: center center;
					width: (8 * 100vw / 1903);
					height: (16 * 100vw / 1903);
					position: absolute;
					top: (7 * 100vw / 1903);
					left: 0;
					@include media($w1024) {
						width: 5px;
						height: 10px;
						top: 8px;
					}
				}
				
				& + li {
					margin: (10 * 100vw / 1903) 0 0;
					@include media($w1024) {
						margin: 0;
					}
				}
			}
		}
		
		& > * {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	
	&__number {
		background-size: contain;
		background-position: center right;
		background-repeat: no-repeat;
		width: (300 * 100vw / 1903);
		height: (124 * 100vw / 1903);
		position: absolute;
		z-index: 50;
		top: (18 * 100vw / 1903);
		right: calc(100% + (75 * 100vw / 1903));
		@include media($w1024) {
			width: 150px;
			height: 67px;
			top: 10px;
			right: calc(100% + 30px);
		}
		@include media($w560) {
			display: none;
		}
	}
	
	&__dots {
		@include translateY;
		counter-reset: num;
		position: absolute;
		z-index: 200;
		right: (75 * 100vw / 1903);
		@include media($w1024) {
			right: 30px;
		}
		@include media($w560) {
			@include translateReset;
			display: flex;
			position: absolute;
			top: 113px;
		}
		
		li {
			@include transition;
			border: (2 * 100vw / 1903) solid $white;
			border-radius: 50%;
			width: (52 * 100vw / 1903);
			height: (52 * 100vw / 1903);
			opacity: 0.5;
			cursor: pointer;
			@include media($w1024) {
				border-width: 2px;
				width: 34px;
				height: 34px;
			}
			
			& + li {
				margin: (23 * 100vw / 1903) 0 0;
				@include media($w1024) {
					margin: 12px 0 0;
				}
				@include media($w560) {
					margin: 0 0 0 10px;
				}
			}
			
			&:before {
				@include translateY;
				content: counter(num, decimal-leading-zero);
				counter-increment: num;
				width: 100%;
				font-size: (20 * 100vw / 1903);
				line-height: (20 * 100vw / 1903);
				text-align: center;
				position: absolute;
				top: calc(50% - (2.5 * 100vw / 1903));
				left: 0;
				@include media($w1024) {
					font-size: 14px;
					line-height: 14px;
					top: calc(50% - 1px);
				}
			}
			
			&.active {
				opacity: 1;
			}
		}
	}
	
	&--sections {
		height: auto;
		
		.page-slider {
			
			&__contact {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				align-items: center;
				margin-top: 16px;
				
				&__phone {
					padding: 16px 0 16px 60px;
					position: relative;
					font-size: (20 * 100vw) / 1920;
					margin-bottom: 16px;
					@media (max-width: 1200px) {
						font-size: 16px;
					}
					
					&:before {
						content: '';
						background: url('../images/services/icon--phone.svg') no-repeat 50% 50%;
						background-size: cover;
						width: 47px;
						height: 47px;
						position: absolute;
						top: 50%;
						left: 0;
						transform: translateY(-50%);
					}
				}
				
				&__button {
					margin-bottom: 16px;
				}
			}
			
			&__slide {
				height: 100vh;
				position: relative;
				padding: 0;
				display: block;
				
				
				&:nth-child(3n+1) {
					.page-slider__addon {
						background-image: url(../images/services--addon-01.svg) !important;
					}
				}
				
				&:nth-child(3n-1) {
					.page-slider__addon {
						background-image: url(../images/services--addon-02.svg) !important;
					}
				}
				
				&:nth-child(3n) {
					.page-slider__addon {
						background-image: url(../images/services--addon-03.svg) !important;
					}
				}
				
				&:nth-child(1) {
					.page-slider__number {
						background-image: url(../images/services--number-01.svg);
					}
				}
				
				&:nth-child(2) {
					.page-slider__number {
						background-image: url(../images/services--number-02.svg);
					}
				}
				
				&:nth-child(3) {
					.page-slider__number {
						background-image: url(../images/services--number-03.svg);
					}
				}
				
				&:nth-child(4) {
					.page-slider__number {
						background-image: url(../images/services--number-04.svg);
					}
				}
				
				@media (min-width: 769px) {
					&--reversed {
						.page-slider__container {
							transform: scaleX(-1);
						}
						
						.page-slider__copy {
							transform: scaleX(-1);
						}
						
						.page-slider__number {
							right: unset;
							left: calc(100% + 30px);
						}
					}
				}
			}
		}
	}
}

$mobile: 576px;
@media(max-width: $mobile) {
	.page-home {
		& .slider {
			height: 410px;
			
			&__image {
				position: relative !important;
				top: 0 !important;
				right: 0 !important;
				
				& img {
					width: auto;
					height: 50vw;
					margin: 0 auto 50px auto;
				}
			}
		}
	}
}