.whatsapp-widget {
	&__link {
		
		box-shadow: 0 1px 10px rgba(255, 255, 255, .1);
		position: fixed;
		bottom: 24px;
		right: 100px;
		width: 60px;
		height: 60px;
		border-radius: 30px;
		background: #25d366;
		color: #fff;
		display: block;
		z-index: 999;
		
		@media (max-width: 767px) {
			bottom: 100px;
			right: 24px;
		}
		
		img {
			color: #fff;
			font-size: 40px;
			position: absolute;
			top: 10px;
			left: 10px;
			width: 40px;
			height: 40px;
		}
	}
}