.page-product {
  .hero {
    background-color: $red;
    width: 100%;
    color: $white;
    padding: (210 * 100vw / 1903) 0 (310 * 100vw / 1903);
    position: fixed;
    z-index: 25;
    top: 0;
    left: 0;
    @include media($w1024) {
      padding: 90px 0 170px;
      position: relative;
      top: auto;
      left: auto;
    }
    @include media($w768) {
      padding: 95px 0 140px;
    }
    @include media($w560) {
      padding: 85px 0 125px;
    }
    &__info {
      margin: 0 0 (35 * 100vw / 1903);
      justify-content: space-between;
      display: flex;
      @include media($w1024) {
        margin: 0 0 15px;
        align-items: center;
      }
      .info {
        &__category {
          position: relative;
          &:before {
            content: '';
            background-color: $white;
            width: (1000 * 100vw / 1903);
            height: (2 * 100vw / 1903);
            position: absolute;
            right: calc(100% + (40 * 100vw / 1903));
            top: (14 * 100vw / 1903);
            @include media($w1024) {
              width: 100px;
              height: 2px;
              right: calc(100% + 15px);
              top: 10px;
            }
          }
          ul {
            flex-wrap: wrap;
            display: flex;
            li {
              font-size: (20 * 100vw / 1903);
              font-weight: 600;
              line-height: (26 * 100vw / 1903);
              @include media($w1024) {
                font-size: 14px;
                line-height: 20px;
              }
              &:after {
                content: ',';
                margin: 0 (6 * 100vw / 1903) 0 0;
                @include media($w1024) {
                  margin: 0 4px 0 0;
                }
              }
              &:last-child {
                &:after {
                  display: none;
                }
              }
              a {
                color: $white;
                &:hover {
                  opacity: 0.75;
                }
              }
            }
          }
        }
      }
    }
    &__name {
      position: relative;
      &:before {
        @include translate;
        content: '';
        background-image: url(../images/products--hero--addon.svg);
        background-size: cover;
        background-position: center right;
        width: (862 * 100vw / 1903);
        height: (170 * 100vw / 1903);
        position: absolute;
        z-index: 25;
        top: calc(50% + (10 * 100vw / 1903));
        left: 0;
        @include media($w1024) {
          display: none;
        }
      }
      h1 {
        position: relative;
        z-index: 50;
      }
    }
  }
}
