* {
	@include box-sizing;
	&:before,
	&:after {
		@include box-sizing;
	}
}

::-moz-selection {
	background: $black;
	color: $white;
}

::selection {
	background: $black;
	color: $white;
}

html {
	height: 100%;
}

body {
	background-color: $white;
	font-family: 'acumin-pro' !important;
	font-size: (15 * 100vw / 1903);
	font-weight: 500;
	line-height: (21 * 100vw / 1903);
	letter-spacing: 0.02em;
	color: $black;
	margin: 0;
	padding: 0;
	@include media($w1024) {
		font-size: 13px;
		line-height: 19px;
	}
}

img {
	margin: 0;
	display: block;
	user-select: none;
}

/* --- [ global styles ] --- */

.wrapper {
	width: 100%;
	overflow: hidden;
}

.container {
	width: 100%;
	max-width: (1260 * 100vw / 1903);
	margin: 0 auto;
	padding: 0 (30 * 100vw / 1903);
	position: relative;
	@include media($w1024) {
		max-width: 100%;
		padding: 0 30px;
	}
	&--smallest {
		max-width: (1110 * 100vw / 1903);
		@include media($w1024) {
			max-width: 100%;
		}
	}
	&--small {
		max-width: (1210 * 100vw / 1903);
		@include media($w1024) {
			max-width: 100%;
		}
	}
	&--big {
		max-width: (1260 * 100vw / 1903);
		@include media($w1024) {
			max-width: 100%;
		}
	}
	&--large {
		max-width: (1320 * 100vw / 1903);
		@include media($w1024) {
			max-width: 100%;
		}
	}
}

.clearfix {
	&:after {
		clear: both;
		display: table;
	}
}

ul {
	li {
		position: relative;
	}
}
