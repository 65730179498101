.page-about-us {
  .content {
    .story {
      padding: (50 * 100vw / 1903) 0 (120 * 100vw / 1903);
      position: relative;
      @include media($w1024) {
        padding: 30px 0 35px;
      }
      &:before {
        content: '';
        background-image: url(../images/about-us--story--addon.svg);
        background-size: cover;
        background-position: center left;
        width: (862 * 100vw / 1903);
        height: (170 * 100vw / 1903);
        position: absolute;
        top: 0;
        right: (-5 * 100vw / 1903);
        @include media($w1024) {
          width: 600px;
          height: 118px;
          top: -8px;
          right: -480px;
        }
        @include media($w560) {
          width: 450px;
          height: 89px;
          top: 0;
          right: -225px;
        }
      }
      h2 {
        margin: 0 0 (70 * 100vw / 1903);
        @include media($w1024) {
          margin: 0 0 40px;
        }
      }
      &__carousel {
        position: relative;
        &:after {
          content: '';
          border-radius: (2 * 100vw / 1903);
          background-color: $red;
          width: (1000 * 100vw / 1903);
          height: (2 * 100vw / 1903);
          position: absolute;
          top: (66.5 * 100vw / 1903);
          left: 100%;
          @include media($w1024) {
            border-radius: 2px;
            width: 100px;
            height: 2px;
            top: 43px;
          }
        }
        .owl-stage-outer {
          overflow-x: hidden;
        }
        .owl-nav,
        .owl-dots {
          display: none;
        }
        .owl-item {
          float: left;
          .carousel {
            &__item {
              position: relative;
              .item {
                &__year {
                  height: (105 * 100vw / 1903);
                  position: relative;
                  @include media($w1024) {
                    height: 60px;
                  }
                  &:before,
                  &:after {
                    content: '';
                    background-color: $red;
                    position: absolute;
                  }
                  &:before {
                    @include transition;
                    border-radius: 50%;
                    width: (14 * 100vw / 1903);
                    height: (14 * 100vw / 1903);
                    top: (60 * 100vw / 1903);
                    left: (40 * 100vw / 1903);
                    @include media($w1024) {
                      width: 10px;
                      height: 10px;
                      top: 39px;
                      left: 15px;
                    }
                  }
                  &:after {
                    border-radius: (2 * 100vw / 1903);
                    width: calc(100% - (24 * 100vw / 1903));
                    height: (2 * 100vw / 1903);
                    top: (66 * 100vw / 1903);
                    left: (59 * 100vw / 1903);
                    @include media($w1024) {
                      border-radius: 2px;
                      width: 100%;
                      height: 2px;
                      top: 43px;
                      left: 20px;
                    }
                  }
                  h3 {
                    font-weight: 600;
                    color: $red;
                  }
                }
              }
              p {
                padding: 0 (70 * 100vw / 1903) 0 0;
                @include media($w1024) {
                  padding: 0 30px 0 0;
                }
              }
              &:hover {
                .item {
                  &__year {
                    &:before {
                      width: (20 * 100vw / 1903);
                      height: (20 * 100vw / 1903);
                      top: (57 * 100vw / 1903);
                      left: (37 * 100vw / 1903);
                      @include media($w1024) {
                        width: 10px;
                        height: 10px;
                        top: 39px;
                        left: 15px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
