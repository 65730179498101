.ajax-loader {
  display: none !important;
}

.wpcf7-form-control-wrap {
  position: relative;
}

.wpcf7-not-valid-tip {
  font-size: (12 * 100vw / 1903) !important;
  font-weight: 500 !important;
  line-height: (18 * 100vw / 1903) !important;
  color: $white !important;
  pointer-events: none;
  position: absolute;
  bottom: (1 * 100vw / 1903);
  right: 0;
  @include media($w1024) {
    font-size: 11px !important;
    line-height: 17px !important;
    bottom: 0;
  }
}

.wpcf7-response-output {
  border: (2 * 100vw / 1920) solid $white !important;
  background-color: $white !important;
  width: calc(100% - (60 * 100vw / 1903));
  margin: 0 !important;
  padding: (14 * 100vw / 1903) (20 * 100vw / 1903) (16 * 100vw / 1903) !important;
  position: absolute;
  bottom: calc(100% + (75 * 100vw / 1903));
  left: (30 * 100vw / 1903);
  @include media($w1024) {
    border: 2px solid $white !important;
    width: 100%;
    margin: 30px 0 -30px !important;
    padding: 10px 15px 12px !important;
    position: relative;
    bottom: auto;
    left: auto;
  }
  &.wpcf7-validation-errors,
  &.wpcf7-mail-sent-ng {
    color: $red !important;
  }
  &.wpcf7-mail-sent-ok {
    color: $green !important;
  }
}

.wpcf7-acceptance {
  span {
    &.wpcf7-list-item {
      margin: 0 0 (35 * 100vw / 1920);
      @include media($w1024) {
        margin: 0 0 25px;
      }
      label {
        cursor: pointer;
        justify-content: space-between;
        align-items: flex-start;
        display: flex;
        input {
          margin: (4 * 100vw / 1920) 0 0;
          @include media($w1024) {
            margin: 4px 0 0;
          }
        }
        span {
          flex: 0 0 calc(100% - (35 * 100vw / 1920));
          font-size: (14 * 100vw / 1920);
          line-height: (18 * 100vw / 1920);
          @include media($w1024) {
            flex: 0 0 calc(100% - 30px);
            font-size: 13px;
            line-height: 17px;
          }
        }
      }
    }
  }
}
