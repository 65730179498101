.header {
	width: 100%;
	height: (158 * 100vw / 1900);
	display: flex;
	position: absolute;
	z-index: 8000;
	top: 0;
	left: 0;
	@include media($w1024) {
		height: 90px;
		z-index: 300;
	}
	@include media($w560) {
		height: 84px;
	}
	
	&__left {
		border-right: (2 * 100vw / 1900) solid $gray;
		flex: 0 0 (475 * 100vw / 1900);
		padding: 0 (45 * 100vw / 1900) 0 (60 * 100vw / 1900);
		justify-content: space-between;
		align-items: center;
		display: flex;
		@include media($w1024) {
			border-right: 0;
			flex: 0 0 240px;
			padding: 0 0 0 30px;
		}
	}
	
	&__logo {
		img {
			width: (216 * 100vw / 1900);
			@include media($w1024) {
				width: 120px;
			}
		}
	}
	
	&__languages {
		position: relative;
		
		.languages {
			&__current {
				border: (2 * 100vw / 1900) solid $gray;
				border-radius: (20 * 100vw / 1900);
				background-color: $white;
				width: (96 * 100vw / 1900);
				height: (40 * 100vw / 1900);
				font-weight: 700;
				font-size: (17 * 100vw / 1900);
				line-height: (23 * 100vw / 1900);
				cursor: pointer;
				padding: 0 (20 * 100vw / 1900) (2 * 100vw / 1900);
				align-items: center;
				display: flex;
				@include media($w1024) {
					border-width: 2px;
					border-radius: 15px;
					width: 70px;
					height: 31px;
					font-size: 14px;
					line-height: 20px;
					padding: 0 15px 1.5px;
				}
				
				&:after {
					@include transition;
					content: '';
					background-image: url(../images/icon--dropdown--gray.svg);
					background-size: contain;
					background-repeat: no-repeat;
					background-position: center center;
					width: (16 * 100vw / 1900);
					height: (40 * 100vw / 1900);
					position: absolute;
					top: 0;
					right: (15 * 100vw / 1900);
					@include media($w1024) {
						width: 11px;
						height: 31px;
						top: 1px;
						right: 12px;
					}
				}
			}
			
			&__dropdown {
				@include transition;
				border: (2 * 100vw / 1900) solid $gray;
				border-radius: (20 * 100vw / 1900);
				background-color: $white;
				width: 100%;
				pointer-events: none;
				opacity: 0;
				padding: (10 * 100vw / 1900) (20 * 100vw / 1900);
				position: absolute;
				top: calc(100% + (5 * 100vw / 1903));
				left: 0;
				@include media($w1024) {
					border-width: 2px;
					border-radius: 15px;
					padding: 7px 15px 9px;
					top: calc(100% + 5px);
				}
				
				ul {
					li {
						font-size: (17 * 100vw / 1900);
						font-weight: 700;
						line-height: (23 * 100vw / 1900);
						text-align: center;
						@include media($w1024) {
							font-size: 14px;
							line-height: 20px;
						}
						
						& + li {
							margin: (4 * 100vw / 1903) 0 0;
							@include media($w1024) {
								margin: 1px 0 0;
							}
						}
						
						a {
							color: $black;
							
							&:hover {
								color: $red;
							}
						}
					}
				}
			}
		}
		
		&.visible {
			.languages {
				&__current {
					&:after {
						transform: rotate(180deg);
						@include media($w1024) {
							top: 0;
						}
					}
				}
				
				&__dropdown {
					pointer-events: auto;
					opacity: 1;
				}
			}
		}
	}
	
	&__right {
		flex: 1 1 auto;
		padding: 0 (70 * 100vw / 1900) 0 (75 * 100vw / 1900);
		justify-content: space-between;
		align-items: center;
		display: flex;
		@include media($w1024) {
			padding: 0 80px 0 0;
			justify-content: flex-end;
		}
		@include media($w560) {
			padding: 0 75px 0 0;
		}
	}
	
	&__menu {
		@include media($w1024) {
			@include transition;
			background-color: $black;
			width: 100vw;
			height: 100vh;
			pointer-events: none;
			opacity: 0;
			align-items: center;
			display: flex;
			position: fixed;
			z-index: 50;
			top: -50px;
			left: 0;
		}
		
		& > ul {
			display: flex;
			@include media($w1024) {
				width: 100%;
				flex-wrap: wrap;
			}
			
			& > li {
				padding: (25 * 100vw / 1920) 0;
				position: relative;
				@include media($w1024) {
					flex: 0 0 100%;
					text-align: center;
				}
				
				.submenu {
					&:after {
						display: block;
					}
				}
				
				& + li {
					margin: 0 0 0 (85 * 100vw / 1900);
					@include media($w1024) {
						margin: 0;
					}
				}
				
				&:hover,
				& > ul:hover {
					opacity: 1;
					pointer-events: auto;
					top: 100%;
				}
				
				& > a {
					font-size: (17 * 100vw / 1900);
					font-weight: 700;
					line-height: (23 * 100vw / 1900);
					color: $black;
					display: inline-block;
					position: relative;
					@include media($w1024) {
						font-size: 14px;
						line-height: 20px;
						color: $white;
						padding: 8px 0;
					}
					
					&.submenu {
						padding-right: 32px;
						@media (max-width: 1024px) {
							padding-right: 0;
						}
					}
					
					&:after {
						@include translateY;
						content: '';
						background-image: url(../images/icon--dropdown--red.svg);
						background-size: contain;
						background-repeat: no-repeat;
						background-position: center center;
						width: (16 * 100vw / 1903);
						//height: (16 * 100vw / 1903);
						height: 100%;
						display: none;
						position: absolute;
						top: calc(50% + (2 * 100vw / 1903));
						//left: calc(100% + (10 * 100vw / 1903));
						right: 0;
						@include media($w1024) {
							width: 10px;
							height: 10px;
							top: calc(50% + 2px);
							left: calc(100% + 9px);
						}
					}
					
					&:hover {
						color: $red;
						@include media($w1024) {
							color: $white;
							opacity: 0.75;
						}
					}
				}
				
				&.current-menu-item {
					a {
						color: $red;
					}
				}
				
				& > ul {
					@include transition;
					border-radius: (20 * 100vw / 1920);
					background-color: $white;
					width: (300 * 100vw / 1920);
					opacity: 0;
					pointer-events: none;
					padding: (20 * 100vw / 1920);
					box-shadow: 0 (5 * 100vw / 1920) (20 * 100vw / 1920) 0 rgba($black, 0.2);
					position: absolute;
					top: calc(100% - (20 * 100vw / 1920));
					left: (-20 * 100vw / 1920);
					z-index: 99999;
					@include media($w1024) {
						display: none;
					}
					
					& > li {
						& + li {
							//padding: (20 * 100vw / 1920) 0 0;
						}
						
						& > a {
							font-size: (16 * 100vw / 1920);
							line-height: (20 * 100vw / 1920);
							padding: (10 * 100vw / 1920) 0;
							display: block;
							font-weight: 500;
							color: $black;
							
							&:hover {
								color: $red;
							}
						}
					}
				}
				
				//&:hover {
				//	& > ul {
				//		opacity: 1;
				//		pointer-events: auto;
				//		top: 100%;
				//	}
				//}
				
				//&:hover,
				//& > ul:hover{
				//	opacity: 1;
				//	pointer-events: auto;
				//	top: 100%;
				//}
			}
		}
		
		&.visible {
			pointer-events: auto;
			opacity: 1;
			top: 0;
		}
	}
	
	&__contact {
		font-size: (15 * 100vw / 1900);
		font-weight: 600;
		line-height: (23 * 100vw / 1900);
		letter-spacing: 0.02em;
		@include media($w1024) {
			font-size: 14px;
			line-height: 18px;
			text-align: right;
		}
		@include media($w560) {
			display: none;
		}
		
		a {
			font-size: (17 * 100vw / 1900);
			@include media($w1024) {
				font-size: 14px;
				display: block;
			}
		}
	}
	
	&__phone {
		display: none;
		@include media($w560) {
			display: block;
		}
		
		img {
			width: 26px;
		}
	}
	
	&__trigger {
		width: 24px;
		height: 18px;
		cursor: pointer;
		overflow: hidden;
		display: none;
		position: absolute;
		z-index: 75;
		top: 36px;
		right: 30px;
		@include media($w1024) {
			display: block;
		}
		@include media($w560) {
			top: 33px;
		}
		
		span {
			@include transition;
			@include translateY;
			background-color: $black;
			width: 100%;
			height: 2px;
			position: absolute;
			left: 0;
			
			&:before,
			&:after {
				@include transition;
				content: '';
				background-color: $black;
				width: 100%;
				height: 2px;
				position: absolute;
				left: 0;
			}
			
			&:before {
				top: -7px;
			}
			
			&:after {
				bottom: -7px;
			}
		}
		
		&.visible {
			position: fixed;
			
			span {
				background-color: transparent;
				
				&:before,
				&:after {
					background-color: $white;
				}
				
				&:before {
					transform: rotate(45deg);
					top: 0;
				}
				
				&:after {
					transform: rotate(-45deg);
					bottom: 0;
				}
			}
		}
	}
	
	&--subpage {
		position: fixed;
		@include media($w1024) {
			position: absolute;
		}
		
		.header {
			&__left {
				border-right-color: transparent;
			}
			
			&__languages {
				.languages {
					&__current {
						border-color: $white;
						background-color: transparent;
						color: $white;
						
						&:after {
							background-image: url(../images/icon--dropdown--white.svg);
						}
					}
					
					&__dropdown {
						border-color: $white;
						background-color: transparent;
						
						ul {
							li {
								a {
									color: $white;
									
									&:hover {
										opacity: 0.75;
									}
								}
							}
						}
					}
				}
			}
			
			&__menu {
				& > ul {
					& > li {
						& > a {
							color: $white;
							
							&:after {
								background-image: url(../images/icon--dropdown--white.svg);
								@include media($w1024) {
									background-image: url(../images/icon--dropdown--red.svg);
								}
							}
							
							&:hover {
								opacity: 0.75;
							}
						}
						
						&.current-menu-item {
							a {
								color: $white;
							}
						}
						
						& > ul {
							& > li {
								& > a {
									color: $black !important;
									
									&:hover {
										color: $red !important;
									}
								}
							}
						}
					}
				}
			}
			
			&__contact {
				color: $white;
				
				a {
					color: $white;
					
					&:hover {
						opacity: 0.75;
					}
				}
			}
			
			&__trigger {
				span {
					background-color: $white;
					
					&:before,
					&:after {
						background-color: $white;
					}
				}
				
				&.visible {
					span {
						background-color: transparent;
					}
				}
			}
		}
	}
}

.page-services,
.page-technology {
	.header {
		position: absolute;
	}
}

.page-template-contact {
	.header--subpage {
		position: absolute !important;
	}
	
	.page-contact {
		.hero {
			position: relative !important;
		}
		
		.map {
			margin-top: 0 !important;
		}
	}
}

.menu-item {
	&:hover .sub-menu,
	&:hover .sub-menu:hover {
		opacity: 1;
		pointer-events: auto;
		top: 100%;
	}
	
	.submenu:hover {
		.sub-menu {
			opacity: 1;
			pointer-events: auto;
			top: 100%;
		}
	}
}

@media (max-width: 1024px) {
	.header--subpage .header__menu > ul > li > ul > li > a {
		color: rgba(255, 255, 255, .8) !important;
	}
	.header__menu > ul > li > ul {
		display: block !important;
		position: static;
		width: 100%;
		background: none;
		opacity: 1;
		pointer-events: all;
		
		> li {
			> a {
				color: rgba(255, 255, 255, .8) !important;
				display: block;
				font-size: 13px;
				line-height: 1.2;
				padding: 4px 15px;
			}
		}
	}
}
