.instagram {
	margin-top: 150px;
	padding: 0 (17 * 100vw / 1903);
	
	@include media($w1024) {
		padding: 0;
	}
	
	@media (max-width: 767px) {
		display: flex;
		flex-wrap: wrap;
	}
	
	&__cover {
		width: 100%;
		max-width: calc(17% - 6px);
		height: auto;
		float: left;
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
		
		@media (max-width: 767px) {
			max-width: 100%;
			background: #EDEDED;
			border-radius: 20px;
		}
		
		img {
			max-width: 100%;
			margin-top: 1px;
			padding: 4px;
			
			@media (max-width: 767px) {
				max-height: 310px;
				margin: 0 auto;
				padding: 0;
			}
		}
	}
	
	.insta-gallery-feed.insta-gallery-square {
		//width: 80%;
		
		@media (max-width: 767px) {
			width: 100%;
		}
		.insta-gallery-list {
			.insta-gallery-item {
				//min-width: 20%;
				max-width: 20%;
				@media (max-width: 767px) {
					width: 100%;
					max-width: 100%;
				}
				&:last-of-type {
				.insta-gallery-item-wrap {
					.insta-gallery-image-wrap {
						background-color: transparent;
						
						.insta-gallery-link {
							img {
									border-top-right-radius: 20px;
									border-bottom-right-radius: 20px;
							}
						}
					}
				}
				}
			}
		}
	}
	.insta-gallery-feed.insta-gallery-spinner {
		height: 300px !important;
	}
}
