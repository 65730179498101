.page-home {
  .prepare-order {
    padding: 0 (17 * 100vw / 1903);
    @include media($w1024) {
			padding: 0;
		}
    &__inner {
      border-radius: (30 * 100vw / 1903);
      background-image: url(../images/home-prepare-order--bg.jpg);
      background-size: cover;
      background-position: center center;
      text-align: center;
      color: $white;
      padding: (100 * 100vw / 1903) 0;
      @include media($w1024) {
  			border-radius: 15px 0 15px 0;
        padding: 40px 0 45px;
  		}
      h2 {
        margin: 0 0 (80 * 100vw / 1903);
        @include media($w1024) {
    			margin: 0 0 40px;
    		}
      }
      ul {
        margin: 0 0 (80 * 100vw / 1903);
        display: flex;
        @include media($w1024) {
    			margin: 0 0 40px;
    		}
        @include media($w768) {
          margin: 0 0 10px;
    			flex-wrap: wrap;
    		}
        li {
          border-left: (1 * 100vw / 1903) solid rgba($white,0.25);
          border-right: (1 * 100vw / 1903) solid rgba($white,0.25);
          flex: 0 0 25%;
          padding: (20 * 100vw / 1903);
          @include media($w1024) {
            border-left-width: 1px;
            border-right-width: 1px;
      			padding: 0 15px;
      		}
          @include media($w768) {
      			flex: 0 0 50%;
            margin: 0 0 30px;
      		}
          @include media($w768) {
            border-left-color: transparent;
            border-right-color: transparent;
      			flex: 0 0 100%;
      		}
          &:first-child {
            border-left-color: transparent;
          }
          &:last-child {
            border-right-color: transparent;
          }
          img {
            height: (128 * 100vw / 1903);
            margin: 0 auto (40 * 100vw / 1903);
            @include media($w1024) {
          		height: 70px;
              margin: 0 auto 20px;
          	}
          }
          h3 {
            margin: 0 0 (45 * 100vw / 1903);
            @include media($w1024) {
          		margin: 0 0 20px;
          	}
          }
          p {
            br {
              @include media($w1024) {
                display: none;
          		}
            }
          }
        }
      }
    }
  }
}
