a {
	@include transition;
	text-decoration: none;
	color: $red;
	&:hover {
		color: $black;
	}
}

h1, h2, h3, h4, h5, h6 {
	font-weight: 700;
	letter-spacing: 0.02em;
	margin: 0;
}

h1 {
	font-size: (73 * 100vw / 1903);
	line-height: (76 * 100vw / 1903);
	letter-spacing: 0;
	@include media($w1024) {
		font-size: 36px !important;
		line-height: 42px !important;
	}
}

h2 {
	font-size: (36 * 100vw / 1903);
	line-height: (42 * 100vw / 1903);
	@include media($w1024) {
		font-size: 24px !important;
		line-height: 30px !important;
	}
	&.home-products,
	&.home-technology,
	&.home-lab,
	&.about-us-copy,
	&.about-us-story,
	&.about-us-certifications,
	&.career-title {
		font-size: (73 * 100vw / 1903);
		line-height: (76 * 100vw / 1903);
		@include media($w1024) {
			font-size: 36px !important;
			line-height: 42px !important;
		}
	}
	&.contact-hero {
		line-height: (36 * 100vw / 1903);
		@include media($w1024) {
			line-height: 30px !important;
		}
	}
	&.services {
		font-size: (60 * 100vw / 1903);
		line-height: (63 * 100vw / 1903);
		@include media($w1024) {
			font-size: 30px !important;
			line-height: 36px !important;
		}
	}
}

h3 {
	font-size: (20 * 100vw / 1903);
	line-height: (26 * 100vw / 1903);
	@include media($w1024) {
		font-size: 16px !important;
		line-height: 22px !important;
	}
	&.home-technology,
	&.home-prepare-order,
	&.about-us-story,
	&.about-us-certifications {
		font-size: (36 * 100vw / 1903);
		line-height: (38 * 100vw / 1903);
		@include media($w1024) {
			font-size: 24px !important;
			line-height: 30px !important;
		}
	}
	&.home-products {
		font-size: (34 * 100vw / 1903);
		line-height: (36 * 100vw / 1903);
		letter-spacing: 0;
		@include media($w1024) {
			font-size: 24px !important;
			line-height: 30px !important;
		}
	}
	&.product-order {
		font-size: (24 * 100vw / 1903);
		line-height: (30 * 100vw / 1903);
		@include media($w1024) {
			font-size: 20px !important;
			line-height: 26px !important;
		}
	}
}

h4 {
	font-size: (15 * 100vw / 1903);
	font-weight: 500;
	line-height: (20 * 100vw / 1903);
	@include media($w1024) {
		font-size: 13px !important;
		line-height: 19px !important;
	}
	&.home-products {
		font-size: (36 * 100vw / 1903);
		line-height: (38 * 100vw / 1903);
		@include media($w1024) {
			font-size: 24px !important;
			line-height: 30px !important;
		}
	}
}

h5 {
	font-size: (16 * 100vw / 1903);
	line-height: (22 * 100vw / 1903);
	letter-spacing: 0.1em;
	text-transform: uppercase;
	color: $red;
	@include media($w1024) {
		font-size: 13px !important;
		line-height: 19px !important;
	}
}

p {
	font-size: (15 * 100vw / 1903);
	font-weight: 500;
	line-height: (20 * 100vw / 1903);
	letter-spacing: 0.02em;
	margin: 0;
	@include media($w1024) {
		font-size: 13px;
		line-height: 19px;
		letter-spacing: 0.01em;
	}
}

ul, ol {
	li {
		font-size: (15 * 100vw / 1903);
		font-weight: 500;
		line-height: (20 * 100vw / 1903);
		letter-spacing: 0.02em;
		position: relative;
		@include media($w1024) {
			font-size: 13px;
			line-height: 19px;
			letter-spacing: 0.01em;
		}
	}
}

strong, b {
	font-weight: 700;
}

.c {
	h2, h3, h4, h5, h6 {
		margin: 0 0 (25 * 100vw / 1903);
		@include media($w1024) {
			margin: 0 0 15px;
		}
	}
	h2 {
		font-size: (42 * 100vw / 1903);
		line-height: (48 * 100vw / 1903);
		@include media($w1024) {
			font-size: 24px !important;
			line-height: 30px !important;
		}
	}
	h3 {
		font-size: (36 * 100vw / 1903);
		line-height: (42 * 100vw / 1903);
		@include media($w1024) {
			font-size: 22px !important;
			line-height: 28px !important;
		}
	}
	h4 {
		font-size: (30 * 100vw / 1903);
		line-height: (36 * 100vw / 1903);
		@include media($w1024) {
			font-size: 20px !important;
			line-height: 26px !important;
		}
	}
	p {
		margin: 0 0 (25 * 100vw / 1903);
		@include media($w1024) {
			margin: 0 0 15px;
		}
	}
	ul {
		li {
			padding: 0 0 0 (20 * 100vw / 1903);
			@include media($w1024) {
				padding: 0 0 0 15px;
			}
			&:before {
				content: '';
				border-radius: 50%;
				background-color: $red;
				width: (7 * 100vw / 1903);
				height: (7 * 100vw / 1903);
				position: absolute;
				top: (8 * 100vw / 1903);
				left: 0;
				@include media($w1024) {
					width: 5px;
					height: 5px;
					top: 8px;
				}
			}
		}
	}
	ol {
		counter-reset: counter;
		margin: (-10 * 100vw / 1903) 0;
		li {
			padding: (28 * 100vw / 1903) 0 (28 * 100vw / 1903) (65 * 100vw / 1903);
			&:before {
				content: counter(counter, decimal-leading-zero);
				counter-increment: counter;
				border: (2 * 100vw / 1903) solid $black;
				border-radius: 50%;
				width: (52 * 100vw / 1903);
				height: (52 * 100vw / 1903);
				font-size: (20 * 100vw / 1903);
				font-weight: 700;
				line-height: (20 * 100vw / 1903);
				padding: 0 0 (2 * 100vw / 1903);
				justify-content: center;
				align-items: center;
				display: flex;
				position: absolute;
				top: (10 * 100vw / 1903);
				left: 0;
			}
		}
	}
	* {
		&:last-child {
			margin: 0 !important;
		}
	}
}
