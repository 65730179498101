.bottom {
  background-color: $white;
  color: $bottom;
  padding: (95 * 100vw / 1903) (70 * 100vw / 1903) (80 * 100vw / 1903);
  display: flex;
  position: relative;
  z-index: 75;
  @include media($w1024) {
    padding: 40px 30px 36px;
    flex-wrap: wrap;
  }
  a {
    color: $bottom;
    &:hover {
      color: $red;
    }
  }
  h3 {
    font-weight: 600;
    margin: 0 0 (45 * 100vw / 1903);
    @include media($w1024) {
      margin: 0 0 15px;
    }
  }
  p {
    max-width: (475 * 100vw / 1903);
    margin: 0 0 (20 * 100vw / 1903);
    @include media($w1024) {
      max-width: none;
      margin: 0 0 15px;
    }
  }
  &__copy {
    flex: 0 0 35%;
    padding: 0 (70 * 100vw / 1903) 0 0;
    @include media($w1024) {
      flex: 0 0 100%;
      padding: 0 0 25px;
    }
    img {
      width: (158 * 100vw / 1903);
      margin: 0 0 (30 * 100vw / 1903);
      @include media($w1024) {
        width: 120px;
        margin: 0 0 20px;
      }
    }
    & > * {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__contact,
  &__menu,
  &__offer {
    padding: (10 * 100vw / 1903);
    @include media($w1024) {
      padding: 0;
    }
  }
  &__contact {
    flex: 0 0 27%;
    @include media($w1024) {
      flex: 0 0 calc(100% / 3);
    }
    @include media($w768) {
      flex: 0 0 100%;
      padding: 0 0 30px;
    }
    @include media($w560) {
      flex: 0 0 60%;
    }
    ul {
      padding: (15 * 100vw / 1903) 0 0;
      display: flex;
      @include media($w1024) {
        padding: 8px 0 0;
      }
      li {
        & + li {
          margin: 0 0 0 (22 * 100vw / 1903);
          @include media($w1024) {
            margin: 0 0 0 12px;
          }
        }
        img {
          height: (25 * 100vw / 1903);
          @include media($w1024) {
            height: 20px;
          }
        }
        a {
          opacity: 0.65;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
  &__menu {
    flex: 0 0 14%;
    @include media($w1024) {
      flex: 0 0 calc(100% / 3);
    }
    @include media($w768) {
      flex: 0 0 calc(100% / 2);
    }
    @include media($w560) {
      flex: 0 0 40%;
      margin: 0 0 25px;
    }
    .menu {
      &__menu {
        ul {
          li {
            & + li {
              margin: (20 * 100vw / 1903) 0 0;
              @include media($w1024) {
                margin: 8px 0 0;
              }
              @include media($w560) {
                margin: 5px 0 0;
              }
            }
            a {
              font-size: (15 * 100vw / 1903);
              line-height: (20 * 100vw / 1903);
              @include media($w1024) {
                font-size: 13px;
                line-height: 19px;
              }
            }
          }
        }
      }
    }
  }
  &__offer {
    flex: 0 0 24%;
    @include media($w1024) {
      flex: 0 0 calc(100% / 3);
    }
    @include media($w768) {
      flex: 0 0 calc(100% / 2);
    }
    @include media($w560) {
      flex: 0 0 100%;
    }
    .offer {
      &__menu {
        margin: 0 0 (30 * 100vw / 1903);
        @include media($w1024) {
          margin: 0 0 20px;
        }
        ul {
          li {
            & + li {
              margin: (20 * 100vw / 1903) 0 0;
              @include media($w1024) {
                margin: 8px 0 0;
              }
              @include media($w560) {
                margin: 5px 0 0;
              }
            }
            a {
              font-size: (15 * 100vw / 1903);
              line-height: (20 * 100vw / 1903);
              @include media($w1024) {
                font-size: 13px;
                line-height: 19px;
              }
            }
          }
        }
      }
    }
  }
}
