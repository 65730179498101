.page-about-us {
  .content {
    border-radius: (30 * 100vw / 1903) (30 * 100vw / 1903) 0 0;
    background-color: $white;
    overflow: hidden;
    margin: (370 * 100vw / 1903) 0 0;
    padding: (160 * 100vw / 1903) 0 0;
    position: relative;
    z-index: 100;
    @include media($w1024) {
      border-radius: 15px 15px 0 0;
      margin: -15px 0 0;
      padding: 40px 0 0;
    }
    &:before {
      content: '';
      background-image: url(../images/about-us--content--addon.svg);
      background-size: cover;
      background-position: center right;
      width: (862 * 100vw / 1903);
      height: (170 * 100vw / 1903);
      position: absolute;
      top: (42 * 100vw / 1903);
      left: (-70 * 100vw / 1903);
      @include media($w1024) {
        width: 600px;
        height: 118px;
        top: 26px;
        left: -100px;
      }
      @include media($w560) {
        width: 450px;
        height: 89px;
        top: 40px;
        left: -115px;
      }
    }
  }
}
