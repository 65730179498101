.page-contact {
  .hero {
    background-color: $red;
    width: 100%;
    color: $white;
    padding: (220 * 100vw / 1903) 0 (110 * 100vw / 1903);
    position: fixed;
    z-index: 25;
    top: 0;
    left: 0;
    @include media($w1024) {
      padding: 105px 0 75px;
      position: relative;
      top: auto;
      left: auto;
    }
    &:before {
      content: '';
      background-image: url(../images/contact--hero--addon.svg);
      background-size: cover;
      background-position: center right;
      width: (862 * 100vw / 1903);
      height: (170 * 100vw / 1903);
      position: absolute;
      bottom: (115 * 100vw / 1903);
      left: (-30 * 100vw / 1903);
      @include media($w1024) {
        width: 600px;
        height: 118px;
        bottom: 110px;
        left: -50px;
      }
      @include media($w560) {
        width: 450px;
        height: 89px;
        bottom: 45%;
      }
    }
    a {
      color: $white;
      &:hover {
        opacity: 0.75;
      }
    }
    &__title {
      padding: 0 (80 * 100vw / 1903) (75 * 100vw / 1903);
      @include media($w1024) {
        margin: 0 0 35px;
        padding: 0 30px;
      }
    }
    &__inner {
      display: flex;
      @include media($w768) {
        flex-wrap: wrap;
      }
    }
    &__data {
      flex: 0 0 50%;
      @include media($w1024) {
        flex: 0 0 300px;
      }
      @include media($w768) {
        flex: 0 0 100%;
        margin: 0 0 35px;
      }
      h2 {
        font-weight: 400;
        margin: 0 0 (40 * 100vw / 1903);
        @include media($w1024) {
          margin: 0 0 20px;
        }
        strong {
          font-weight: 700;
        }
      }
      p {
        max-width: (380 * 100vw / 1903);
        margin: 0 0 (20 * 100vw / 1903);
        @include media($w1024) {
          max-width: calc(100% - 30px);
          margin: 0 0 15px;
        }
        @include media($w768) {
          max-width: none;
        }
      }
      & > * {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &__form {
      flex: 0 0 50%;
      @include media($w1024) {
        flex: 0 0 calc(100% - 300px);
      }
      @include media($w768) {
        flex: 0 0 100%;
      }
      h2 {
        margin: 0 0 (45 * 100vw / 1903);
        @include media($w1024) {
          margin: 0 0 20px;
        }
      }
      .form {
        &__submit {
          justify-content: flex-end;
          display: flex;
          position: relative;
          &:before,
      		&:after {
      			@include transition;
            @include translateY;
      			content: '';
      			background-image: url(../images/icon--btn-arrow--white.svg);
      			background-size: contain;
      			background-repeat: no-repeat;
      			background-position: center center;
      			width: (22 * 100vw / 1903);
      			height: (22 * 100vw / 1903);
      			pointer-events: none;
      			position: absolute;
      			right: (22 * 100vw / 1903);
            @include media($w1024) {
              width: 15px;
        			height: 15px;
        			right: 15px;
            }
      		}
      		&:after {
      			background-image: url(../images/icon--btn-arrow--red.svg);
      			opacity: 0;
      		}
          &:hover {
            &:before {
              opacity: 0;
            }
            &:after {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
